<template>
    <div class="create">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Create Post</div>
        </div>

        <div class="main">
            <div class="info">
                <div class="dp">
                    <img :src="$root.$data.dp"/>
                </div>
                <div>
                    <div class="name">{{$root.$data.firstname}} {{$root.$data.lastname}}</div>
                    <div class="username">@{{$root.$data.username}}</div>
                </div>
                <div class="opt" @click="togglepop('show_opt')">
                    <optionicon :size="'15px'" :style="{'height': '15px'}"/>
                </div>
            </div>

            <textarea-autosize
                placeholder="What's on your mind?"
                ref="myTextarea"
                v-model="text"
                :min-height="30"
            />

            <div class="image" v-if="images_count == 1">
                <div class="remove" @click="remove_image(0)">
                    <remove :size="'30px'"/>
                </div>
                <img :src="images[0]"/>
            </div>
            
            <swiper v-if="images_count > 1" ref="mySwiper" :options="swiperOptions" class="swiper">
                <swiper-slide class="slide" v-for="(image, i) in images" :key="i">
                    
                <div class="image">
                    <div class="remove" @click="remove_image(i)">
                        <remove :size="'30px'"/>
                    </div>
                    <img :src="image"/>
                </div>
                </swiper-slide>
                
                <div class="swiper-pagination" slot="pagination"></div>

            </swiper>

        </div>

        <div class="bottom">
            <div class="icon" @click="upload()">
                <imageicon :size="'25px'" :style="{'height': '25px'}"/>
            </div>
            <div class="icon">
                <videoicon :size="'25px'" :style="{'height': '25px'}"/>
            </div>
            <loading :height="'35px'" :width="'76px'" class="l" v-if="loading"/>
            <div class="btn" v-else :class="[text.length > 0 ? 'a' : '']" @click="post()">Post</div>
        </div>

        
        <div class="popup" v-if="pop">
            <div class="dark" @click="closepop()"></div>

            <transition name="slide">
                <new_post_options :data="settings" @new_settings="settings = $event" v-if="show_opt"/>
            </transition>
        </div>

        <input type="file" accept="image/*" class="hidden" ref="image" @change="handleupload($event)"/>
    </div>
</template>

<script>

import Localbase from 'localbase'
let db = new Localbase('ikotun')

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// import backVue from '../../icons/back.vue';

import back from '../../icons/back.vue';

import optionicon from '../../icons/option.vue';

import remove from '../../icons/remove.vue'

import imageicon from '../../icons/image.vue';
import videoicon from '../../icons/video.vue';

import loading from '../../components/loading.vue'

import new_post_options from '../../components/create/options.vue'

import * as imageConversion from 'image-conversion';

export default {
    components: {
        Swiper, SwiperSlide,
        back,
        optionicon,
        remove,
        imageicon, videoicon,
        loading,
        new_post_options
    },
    data() {
        return {
            loading: false,
            text: '',
            pop: false,
            show_opt: false,
            settings: {
                copy: true,
                comment: true,
                reaction: true
            },

            images_count: 0,
            compressed: [],
            images: [],

            
            swiperOptions: {
                autoHeight: true,
                spaceBetween: 17,
                pagination: {
                    el: '.swiper-pagination',
                    bulletActiveClass: 'pagination-active',
                    clickable: true
                }
            }
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    methods: {
        
        upload() {
            if (this.images_count == 3) {
                this.three(); return
            }
            this.$refs['image'].click()
        },
        handleupload(event) {
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                
                this.compress(picture)
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this.images.push(event.target.result)
                    this.images_count++

                    this.$refs['image'].value = ''

                    setTimeout(() => {
                        if (this.images_count > 1) {
                            this.swiper.slideTo(this.images_count -1)
                        }
                    }, 200);
                }
            }
        },
        compress(file) {
            let name = file.name
            
            imageConversion.compressAccurately(file, 70).then(res=>{
                this.is_image = true
                
                this.compressed.push(new File([res], name))
            })
        },
        remove_image(i) {
            this.images_count--

            this.images.splice(i, 1)
            this.compressed.splice(i, 1)
        },
 
        back() {
            // if (this.loading) { return }
            setTimeout(() => {
                this.$router.go(-1)
            }, 200);
        },
        togglepop(what) {
            this.pop = true
            setTimeout(() => {
                this[what] = true
            }, 100);
        },
        closepop() {
            this.show_opt = false
            setTimeout(() => {
                this.pop = false
            }, 200);
        },
        post() {
            
            let settings = {}
            for( let key in this.settings ) {
                if (!this.settings[key]) {
                    settings[key] = false
                }
            }
            
            let text = this.text.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
            let trim_text = text.trim()

            if (trim_text == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('text', this.text)
            form.append('settings', JSON.stringify(settings))

            let i = 1
            let media = []
            for (let x of this.compressed) {
                let id = 'image_' + i
                form.append(id, x)
                media.push(id)
                i++
            }
            form.append('media', JSON.stringify(media))


            this.$http.post('/post/new', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.$root.$data.posts++
                    db.collection('auth').doc({ id: 1 }).update({
                        'posts': this.$root.$data.posts
                    })

                    let post = {
                        'id': r.id,
                        'username': this.$root.$data.username,
                        'date': r.date,
                        'type': 'compose',
                        'text': trim_text,
                        'media': JSON.stringify(r.media),
                        'settings': JSON.stringify(settings),
                        'reaction': null,
                        'stat': {
                            'like': 0,
                            'laugh': 0,
                            'waw': 0,
                            'sad': 0,
                            'angry': 0,
                            'reactions': 0,
                            'comments': 0
                        },
                        'user': {
                            'dp': this.$root.$data.dp,
                            'cover': this.$root.$data.cover,
                            'firstname': this.$root.$data.firstname,
                            'lastname': this.$root.$data.lastname,
                            'created': this.$root.$data.created,
                            'followers': this.$root.$data.followers,
                            'followings': this.$root.$data.followings,
                            'location': this.$root.$data.location,
                            'posts': this.$root.$data.posts,
                            'comments': this.$root.$data.comments,
                            'username': this.$root.$data.username,
                            'verified': this.$root.$data.verified
                        }
                    }
                    localStorage.setItem('post', JSON.stringify(post))

                    this.text = ''
                    this.loading = false
                    this.images = []
                    this.compressed = []

                    this.$router.push('/post/' + r.id)
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Your post is empty'
        },
        three: {
            type: 'info',
            title: 'Three',
            message: 'Only three images can be added'
        }
    }
}
</script>

<style scoped>
    .create {
        height: 100vh;
        width: 100%;
        background-color: white;
    }
    .head {
        display: grid;
        /* grid-template-columns: calc(50% - 40px) 50%; */
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
        position: relative;
    }
    .back {
        position: absolute;
        left: 0px;
        top: 0px;
        height: 50px;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .back:focus, .back:active {
        background-color: var(--trans);
    }
    .title {
        font-weight: 500;
        text-align: center;
    }

    .main {
        height: calc(100vh - 107px);
        overflow: auto;
    }

    .info {
        padding: 15px 15px;
        display: flex;
        gap: 20px;
        align-items: center;
    }
    .dp {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }
    .dp img {
        width: 40px;
    }
    .name {
        font-weight: 500;
    }
    .username {
        font-size: 12px;
        color: grey;
    }
    .opt {
        margin-left: auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        fill: var(--main);
        transition: .2s
    }
    .opt:active, .opt:focus {
        background-color: var(--main);
        fill: white;
    }

    textarea {
        padding: 15px;
        padding-top: 5px;
        width: 100%;
        box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        outline: none;
        font-size: 20px;
    }

    .image {
        position: relative;
    }
    .remove {
        position: absolute;
        top: 20px;
        right: 20px;
        fill: var(--main);

        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid white;
    }
    .image img {
        width: 100%;
        display: block;
    }
    .swiper {
        padding: 0px 20px;
        padding-bottom: 20px;
    }
    .slide {
        border-radius: 10px;
        overflow: hidden;
    }
    .swiper-pagination {
        bottom: 0px;
    }




    .bottom {
        padding: 0px 15px;
        padding-left: 5px;
        border-top: 1px solid #ddd;
        display: flex;
        align-items: center;
        /* gap: 15px; */
        fill: var(--main);
    }
    .icon {
        height: 56px;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active, .icon:focus {
        background-color: var(--trans);
    }

    .l {
        margin-left: auto;
    }
    .btn {
        padding: 8px 20px;
        border-radius: 5px;
        background-color: var(--trans);
        color: var(--main);
        font-weight: 500;
        margin-left: auto;
        transition: .2s;
    }
    .a {
        background-color: var(--main);
        color: white;
    }



    

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        z-index: 3;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -50vh;
        }
        to {
            margin-bottom: 0px;
        }
    }

    
    .hidden {
        display: none;
    }
</style>