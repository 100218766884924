<template>
    <div class="options">
        <div class="title">New Post Options</div>
        <div class="main">
            <div class="item">
                <copy :size="'20px'"/>
                <div>
                    <div class="t">Copying</div>
                    <div class="label">Allow other users to copy this post</div>
                </div>
                <div class="switch">
                    <toggle-button :color="$root.$data.c.main" :labels="true" @change="changed()"
                    v-model="settings.copy"/>
                </div>
            </div>
            <div class="item">
                <comment :size="'20px'"/>
                <div>
                    <div class="t">Comment</div>
                    <div class="label">Allow comment feature on this post</div>
                </div>
                <div class="switch">
                    <toggle-button :color="$root.$data.c.main" :labels="true" @change="changed()"
                    v-model="settings.comment"/>
                </div>
            </div>
            <div class="item">
                <like :size="'20px'"/>
                <div>
                    <div class="t">Reactions</div>
                    <div class="label">Allow users to react on this post</div>
                </div>
                <div class="switch">
                    <toggle-button :color="$root.$data.c.main" :labels="true" @change="changed()"
                    v-model="settings.reaction"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import copy from '../../icons/copy.vue';
import comment from '../../icons/comment.vue'
import like from '../../icons/liked.vue'

import { ToggleButton } from 'vue-js-toggle-button'

export default {
    components: {
        copy, comment, like,
        ToggleButton
    },
    props: [ 'data' ],
    created() {
        this.settings = this.data
    },
    data() {
        return {
            settings: {
                copy: true,
                comment: true,
                reaction: true
            }
        }
    },
    methods: {
        changed() {
            this.$emit('new_settings', this.settings)
        }
    }
}
</script>

<style scoped>

    .options {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        max-height: 50vh;
        padding: 10px 0px;
        overflow: auto;
    }
    .item {
        display: flex;
        align-items: center;
        gap: 20px;
        fill: #555;
        padding: 10px 20px;
    }
    .t {
        font-weight: 500;
        font-size: 14px;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
        color: grey;
    }
    .switch {
        margin-left: auto;
    }
</style>